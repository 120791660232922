/** @define breadscrumb;  weak */
.breadscrumb {
  position: absolute;
  bottom: 15px;
  left: 30px;
}
@media only screen and (min-width: 1200px) {
  .breadscrumb {
    left: 90px;
  }
}
@media only screen and (min-width: 1460px) {
  .breadscrumb {
    left: 90px;
    bottom: 30px;
  }
}
.breadscrumb a,
.breadscrumb p {
  color: #ffffff;
}
.breadscrumb a:hover {
  color: #f7a810;
  text-decoration: none;
}
.breadscrumb span {
  margin: 0 15px;
  color: #f7a810;
}
.breadscrumb p:last-child span {
  display: none;
}
/*# sourceMappingURL=css/breadscrumb.css.map */